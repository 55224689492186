<template>
  <div class="helpDetail articleBox">
    <div class="m-nav">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="container">
        <el-breadcrumb-item :to="{ path: '/service/help' }">
          {{ $t("service.navArr[1].name") }}
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/service/help' }">
          {{ detail.cate_name }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ detail.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container articleContent">
      <div class="title listItemTitle">{{ detail.name }}</div>
      <div class="content" v-html="detail.content"></div>
      <div class="clear"></div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
export default {
  components: {},
  computed: {
    article_id() {
      return JSON.parse(this.$route.query.article_id);
    },
  },
  watch: {
    article_id(n, o) {
      if (n !== o) this.getDetail(n);
    },
  },
  data() {
    return {
      detail: {
        content: "",
      },
    };
  },
  created() {
    this.getDetail(this.article_id);
  },
  methods: {
    getDetail(article_id) {
      // console.log(this.info);
      api.service.articleDetail({ article_id }).then((res) => {
        // console.log(res);
        if (res) this.detail = res;
        else this.detail = {};
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.helpDetail {
  padding: 26px 0 40px;
  min-height: calc(100vh - 327px);
  // background-color: var(--bgGray);
  // .container {
  //   background-color: var(--whiteColor);
  // }
  .listItemTitle {
    margin-top: 0;
  }
  // .m-nav {
  //   .container {
  //     background-color: var(--bgGray);
  //   }
  // }
  .title {
    padding: 0px 0 17px;
    border-bottom: 1px solid var(--borderColor);
  }
  .content {
    padding-top: 54px;
  }
  .clear {
    clear: both;
  }

  ::v-deep ol,
  ::v-deep ul,
  ::v-deep li {
    list-style: auto;
  }
  ::v-deep *[type="i"] li {
    list-style-type: lower-roman;
  }
  ::v-deep *[type="a"] li {
    list-style-type: lower-alpha;
  }
}
</style>
